<template>
    <div class=" ">
        <el-card>
            <div slot="header" class="card_title">
                <span>权限管理/新增人员</span>
            </div>
            <div class="add_main hidden_scroll">
                <span class="font_weight_bold">新增(编辑)人员</span>
                <div class="margin_top_20">
                    <el-form label-position="right" label-width="120px" :model="formData" :rules="rule" ref="formData">
                        <el-form-item label="账号" prop="account" class="margin_top_20">
                            <el-input v-model="formData.account" placeholder="名称"></el-input>
                        </el-form-item>
                        <el-form-item label="姓名" prop="truename">
                            <el-input v-model="formData.truename" placeholder=""></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="性别">
                            <el-radio v-model="radio" label="1">男</el-radio>
                            <el-radio v-model="radio" label="2">女</el-radio>
                            <el-radio v-model="radio" label="2">保密</el-radio>
                        </el-form-item> -->

                        <el-form-item label="手机号" prop="mobile">
                            <el-input v-model="formData.mobile" placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="password">
                            <el-input v-model="formData.password"  placeholder="" :disabled="$route.query.type=='edit'"></el-input>
                        </el-form-item>
                        <el-form-item label="重复密码" prop="checkPass">
                            <el-input v-model="formData.checkPass" placeholder="" :disabled="$route.query.type=='edit'"></el-input>
                        </el-form-item>

                        <!-- <el-form-item label="电子邮箱">
                            <el-input v-model="formData.region"></el-input>
                        </el-form-item>

                        <el-form-item label="出生日期">
                            <el-date-picker v-model="formData.region" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item> -->
                        <el-form-item label="当前状态">
                            <el-switch v-model="formData.state" active-value="1" inactive-value="2"
                                active-color="#13ce66" inactive-color="#ff4949">
                            </el-switch>
                        </el-form-item>

                        <el-form-item label="选择角色" prop="role_id">
                            <!-- <el-checkbox-group v-model="checkedRules" :min="1" :max="2">
                                <el-checkbox v-for="(item,index) in rules" :label="item.id"
                                    :key="index">{{item.name}}</el-checkbox>
                            </el-checkbox-group> -->
                            <el-radio-group v-model="formData.role_id">
                                <el-radio v-for="(item, index) in rules" :key="index" :label="item.id">{{ item.name
                                    }}</el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item>
                            <div class="flex justify_content_c align_items_c">
                                <el-button type="primary" class="custom_btn_width_100"
                                    @click="submit('formData')">保存</el-button>
                                <el-button class="custom_btn_width_100" @click="$router.push({path:'/limitsauthority/personnel'})">返回</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
    import { getRoles, addAccount, editAccount } from "@/api/limitsauthority";
    import { validateEqNotNull, validateNull, validatePhone } from "@/utils/validate";
    export default {
        name: "",
        data() {
            return {
                value: "",
                formData: {
                    account: "",
                    password: "",
                    truename: "",
                    mobile: "",
                    role_id: "",
                    state: "",
                    checkPass: "",
                },
                rule: {
                    account: [{ validator: validateNull, trigger: "blur", required: true }],
                    truename: [{ validator: validateNull, trigger: "blur", required: true }],
                    mobile: [{ validator: validateNull, trigger: "blur", required: true }, { validator: validatePhone, trigger: "blur" }],
                    role_id: [{ validator: validateNull, trigger: "change", required: true }],
                    password: [{ validator: validateNull, trigger: "blur", required: true }],
                    checkPass: [
                        {
                            validator: this.validateEqNotNullPass,
                            trigger: "blur",
                            required: true
                        },
                    ],
                },
                checkedRules: [],
                rules: [],
            };
        },
        watch: {
            "$route.query.row": [
                function handler1() {
                    if (!this.$route.query.row) {
                        this.clearFormAdd();
                    } else {
                        this.formData = this.$route.query.row;
                        this.formData["checkPass"] = this.formData.password;
                    }
                },
            ],
        },
        created() {
            this.getDataList();
            if (!this.$route.query.row) {
                this.clearFormAdd();
            } else {
                this.formData = this.$route.query.row;
                this.formData["checkPass"] = this.formData.password;
                //    var roleList= this.formData.permissions.split(",")
                //    console.log(roleList);
            }
        },
        methods: {
            validateEqNotNullPass(rule, value, callback) {
                if (value === '') {
                    callback(new Error("输入不能为空！"))
                } else if (value != this.formData.password) {
                    console.log(value + '#');
                    console.log(this.formData.password + '!');
                    callback(new Error("两次输入不一致"));
                } else {
                    callback()
                }
            },
            getDataList() {
                const that = this;
                getRoles({})
                    .then((res) => {
                        if (res.code == 200) {
                            that.rules = res.data;
                        } else {
                            that.$message.error(res.message);
                        }
                    })
                    .catch((error) => {
                        that.$message.error(error.message);
                    });
            },
            // 新增资讯提交
            submit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        const that = this;
                        if (this.$route.query.type == "edit") {
                            editAccount(that.formData)
                                .then((res) => {
                                    if (res.code == 200) {
                                        that.$router.push({ path: "/limitsauthority/personnel" });
                                    } else {
                                    }
                                })
                                .catch((error) => {
                                });
                        } else {
                            addAccount(that.formData)
                                .then((res) => {
                                    if (res.code == 200) {
                                        that.$router.push({ path: "/limitsauthority/personnel" });
                                    } else {
                                    }
                                })
                                .catch((error) => {
                                });
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });

            },
            clearFormAdd() {
                for (let key in this.formData) {
                    this.formData[key] = "";
                }
            },
        },
    };
</script>

<style scoped>
    .main {
        width: calc(100% - 40px);
    }

    .add_main {
        width: 70%;
        height: 81vh;
        overflow-y: scroll;
        margin: 0 auto;
    }
</style>